import { IAuthorization } from "common/interfaces/auth";
import { BaseApiService } from "./BaseApiService";

class IdentityRepository extends BaseApiService {
  constructor() {
    super("identity");
  }

  verificationEmail = async (body: IAuthorization) => {
    const authDataRequest =
      "UserName=" + encodeURIComponent(body.login || "") + "&Password=" + encodeURIComponent(body.password || "");

    return this.post<string>("Admin/TwoFactor/SendCode", authDataRequest, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  };

  approveCode = async (body: IAuthorization) => {
    const authDataRequest =
      "client_id=" +
      encodeURIComponent("admin.client") +
      "&client_secret=" +
      encodeURIComponent("9F45EA47-9BD6-48D8-B218-273A256DB093") +
      "&grant_type=admin_two_factor" +
      "&scope=" +
      encodeURIComponent("openid profile offline_access admin-api policy") +
      "&security_token=" +
      encodeURIComponent(body.securityToken || "") +
      "&verification_token=" +
      encodeURIComponent(body.code || "");

    return this.post<string>("connect/token", authDataRequest, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  };

  getUserInfo = async (token: string) =>
    this.get("connect/userinfo", { headers: { Authorization: `Bearer ${token}` } });
}

export const identityRepository = new IdentityRepository();
