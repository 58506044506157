import { createAsyncThunk } from "@reduxjs/toolkit";
import { promoMatchRepository } from "api/promoMatchRepository";
import { EventOfPromoMatch, PromoMatchInfo } from "common/interfaces/matchPromo";
import { ThunkApiType } from "../../common/interfaces/common";

export const getPromoMatches = createAsyncThunk<PromoMatchInfo[], undefined, ThunkApiType>(
  "promoMatch/promoMatchList",
  async (_, { rejectWithValue }) => {
    return await promoMatchRepository.fetchPromoCards().catch((error) => rejectWithValue({ error }));
  }
);

export const getEventsOfPromoMatch = createAsyncThunk<EventOfPromoMatch[], string | undefined, ThunkApiType>(
  "promoMatch/eventsOfPromoMatchList",
  async (teamId, { rejectWithValue }) => {
    return await promoMatchRepository.fetchEventsOfPromoMatch(teamId).catch((error) => rejectWithValue({ error }));
  }
);
