import { createAsyncThunk } from "@reduxjs/toolkit";
import { pollsRepository } from "api/pollsRepository";
import { ThunkApiType } from "common/interfaces/common";
import { IPoll, IPollFilter, IPollResponse } from "pages/polls/interfaces/IPoll";

export const getPollsByFilter = createAsyncThunk<IPollResponse, IPollFilter, ThunkApiType>(
  "polls/byFilter",
  async (filters, { rejectWithValue }) => {
    return await pollsRepository.fetchByFilter(filters).catch((error) => rejectWithValue({ error }));
  }
);

export const getPollById = createAsyncThunk<IPoll, IPoll["Id"], ThunkApiType>(
  "poll/byId",
  async (filters, { rejectWithValue }) => {
    return await pollsRepository.fetchById(filters).catch((error) => rejectWithValue({ error }));
  }
);
