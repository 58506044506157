import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Form, Layout, Modal, Typography } from "antd";
import { promoMatchRepository } from "api/promoMatchRepository";
import { theme } from "assets/theme/theme";
import { toISOString } from "common/helpers/toISOString";
import { PromoMatchInfo, PromoMatchStatus, SortingPromoMatchInfo } from "common/interfaces/matchPromo";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { noticeActions } from "store/notice/notice";
import { getPromoMatches } from "store/promoMatch/promoMatchAsync";
import { promoMatchesListSelector } from "store/promoMatch/promoMatchSelector";
import styled from "styled-components";
import { HeaderText } from "ui/HeaderText";
import { Loader } from "ui/Loader";
import { MatchPromoCard } from "./matchPromoCard";

const { Header, Content } = Layout;
const { Title } = Typography;

interface IProps {
  access: boolean;
}

export const MatchesPromo = (props: IProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { promoMatchesList, isLoading } = useSelector(promoMatchesListSelector);
  const [FirstCardEvents, setFirstCardEvents] = useState<PromoMatchInfo["Event"][]>([]);
  const [SecondCardEvents, setSecondCardEvents] = useState<PromoMatchInfo["Event"][]>([]);
  const [ThirdCardEvents, setThirdCardEvents] = useState<PromoMatchInfo["Event"][]>([]);
  const [FourthCardEvents, setFourthCardEvents] = useState<PromoMatchInfo["Event"][]>([]);
  const [FifthCardEvents, setFifthCardEvents] = useState<PromoMatchInfo["Event"][]>([]);

  useEffect(() => {
    dispatch(getPromoMatches());
  }, []);

  const onSave = async () => {
    const data = form.getFieldsValue();

    const PromoCards = Object.keys(data)
      .map((key) => {
        return data[key]
          ? {
              StartPublish:
                data[key]?.["DateTime"] &&
                toISOString(moment(+data[key]?.["DateTime"]?.[0]?.set({ second: 0, millisecond: 0 }) - 10800000)),
              EndPublish:
                data[key]?.["DateTime"] &&
                toISOString(moment(+data[key]?.["DateTime"]?.[1]?.set({ second: 0, millisecond: 0 }) - 10800000)),
              Status: data[key]?.["Status"],
              CardType: key,
              Event: [
                ...FirstCardEvents,
                ...SecondCardEvents,
                ...ThirdCardEvents,
                ...FourthCardEvents,
                ...FifthCardEvents,
              ].find((event) => event?.Id === data[key]?.MatchId),
            }
          : undefined;
      })
      .filter((elem) => elem?.Status && elem.Status !== PromoMatchStatus.Hide);

    // @ts-ignore: next-line
    await promoMatchRepository.savePromoMatches({ PromoCards }).then(() => {
      dispatch(noticeActions.add({ message: t(`matchPromo.success`), closable: true }));
      dispatch(getPromoMatches());
    });
  };

  const onReset = (fieldName: keyof SortingPromoMatchInfo) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [fieldName]: promoMatchesList[fieldName],
    });
  };
  const onDefault = (fieldName: keyof SortingPromoMatchInfo) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [fieldName]: {
        Status: PromoMatchStatus.None,
        CardType: fieldName,
        TeamId: undefined,
        DateTime: undefined,
        MatchId: undefined,
      },
    });
  };

  const onHidden = (fieldName: keyof SortingPromoMatchInfo, isShow: boolean) => {
    if (isShow) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        [fieldName]: {
          ...promoMatchesList[fieldName],
          Status: promoMatchesList[fieldName]?.Status || PromoMatchStatus.None,
        },
      });
    } else {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        [fieldName]: {
          Status: PromoMatchStatus.Hide,
          CardType: fieldName,
          TeamId: undefined,
          DateTime: undefined,
          MatchId: undefined,
        },
      });
    }
  };

  const onSaveConfirm = () => {
    Modal.confirm({
      title: <HeaderText>Вы действительно хотите опубликовать?</HeaderText>,
      icon: <QuestionCircleOutlined style={{ color: theme.colors.red1 }} />,
      content: "После подтверждения карточки станут доступны для всех пользователей",
      okText: t("allPages.buttonsText.confirm"),
      cancelText: t("allPages.buttonsText.cancel"),
      onOk: () => {
        onSave();
      },
    });
  };

  const onDefaultAll = () => {
    Modal.confirm({
      title: <HeaderText>Вы действительно хотите сбросить по умолчанию?</HeaderText>,
      icon: <QuestionCircleOutlined style={{ color: theme.colors.red1 }} />,
      content: "После подтверждения настройки для всех карточек станут по умолчанию",
      okText: t("allPages.buttonsText.confirm"),
      cancelText: t("allPages.buttonsText.cancel"),
      onOk: () => {
        form.setFieldsValue({
          FirstCard: {
            Status: PromoMatchStatus.None,
            CardType: "FirstCard",
            TeamId: undefined,
            DateTime: undefined,
            MatchId: undefined,
          },
          SecondCard: {
            Status: PromoMatchStatus.None,
            CardType: "SecondCard",
            TeamId: undefined,
            DateTime: undefined,
            MatchId: undefined,
          },
          ThirdCard: {
            Status: PromoMatchStatus.None,
            CardType: "ThirdCard",
            TeamId: undefined,
            DateTime: undefined,
            MatchId: undefined,
          },
          FourthCard: {
            Status: PromoMatchStatus.None,
            CardType: "FourthCard",
            TeamId: undefined,
            DateTime: undefined,
            MatchId: undefined,
          },
          FifthCard: {
            Status: PromoMatchStatus.None,
            CardType: "FifthCard",
            TeamId: undefined,
            DateTime: undefined,
            MatchId: undefined,
          },
        });

        onSave();
      },
    });
  };

  return (
    <>
      <HeaderContent>
        <CardTitle level={4}>{t("matches.title")}</CardTitle>
        <CardState></CardState>
      </HeaderContent>
      <Content style={{ padding: 24, margin: 0 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Container>
            <Form
              form={form}
              requiredMark={false}
              layout="vertical"
              initialValues={promoMatchesList}
              validateTrigger="onSubmit"
            >
              <CardsContainer>
                <MatchPromoCard
                  form={form}
                  setCardEvents={setFirstCardEvents}
                  matchPromo={promoMatchesList?.FirstCard}
                  cardType={"FirstCard"}
                  default={onDefault}
                  reset={onReset}
                  onHidden={onHidden}
                />
                <MatchPromoCard
                  form={form}
                  setCardEvents={setSecondCardEvents}
                  matchPromo={promoMatchesList?.SecondCard}
                  cardType={"SecondCard"}
                  default={onDefault}
                  reset={onReset}
                  onHidden={onHidden}
                />
                <MatchPromoCard
                  form={form}
                  setCardEvents={setThirdCardEvents}
                  matchPromo={promoMatchesList?.ThirdCard}
                  cardType={"ThirdCard"}
                  default={onDefault}
                  reset={onReset}
                  onHidden={onHidden}
                />
                <MatchPromoCard
                  form={form}
                  setCardEvents={setFourthCardEvents}
                  matchPromo={promoMatchesList?.FourthCard}
                  cardType={"FourthCard"}
                  default={onDefault}
                  reset={onReset}
                  onHidden={onHidden}
                />
                <MatchPromoCard
                  form={form}
                  setCardEvents={setFifthCardEvents}
                  matchPromo={promoMatchesList?.FifthCard}
                  cardType={"FifthCard"}
                  default={onDefault}
                  reset={onReset}
                  onHidden={onHidden}
                />
              </CardsContainer>
              <Form.Item shouldUpdate>
                {() => (
                  <ButtonContainer>
                    <Button style={{ marginRight: 8 }} onClick={onDefaultAll} danger>
                      {t("allPages.buttonsText.defaultAll")}
                    </Button>
                    <Button onClick={onSaveConfirm} type="primary" htmlType="submit">
                      {t("allPages.buttonsText.save")}
                    </Button>
                  </ButtonContainer>
                )}
              </Form.Item>
            </Form>

            <PromoInfoBlock>
              <p>
                <GreenText>Отображается</GreenText> - Матч доступен на сайте и в МП и отображается на выбранной
                карточке.{" "}
              </p>
              <p>
                <YellowText>Запланирован</YellowText> - Матч запланирован. Начнет отображается при достижении указанного
                периода.
              </p>
              <p>
                <RedText>По умолчанию</RedText> - Матчи сортируются по дате.
              </p>
              <br />
              <p>Доступно только для основной команды Спартак.</p>
              <p>Для отображения нужного матча, укажите его в требуемой карточке.</p>
              <p>
                <RedText>Важно! </RedText>
                Для возврата отображения матча в данной карточке по умолчанию и в автоматическом режиме,необходимо
                нажать “По умолчанию”
              </p>
            </PromoInfoBlock>
          </Container>
        )}
      </Content>
    </>
  );
};

const Container = styled.div`
  padding: 23px 16px;
  background: ${theme.colors.white};
  width: 100%;
  height: 100%;
`;
const CardsContainer = styled.div`
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-gap: 21px;
  flex-wrap: wrap;
  /* width: 100%; */
  /* gap: 21px; */

  /* & > div {
    flex: 1 1 auto;
  } */
`;

const HeaderContent = styled(Header)`
  padding: 0 24px;
  background-color: ${theme.colors.white};
  flex-basis: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CardTitle = styled(Title)`
  margin-bottom: 0 !important;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px !important;
`;
const CardState = styled.div`
  margin-left: auto;
`;
const GreenText = styled.span`
  color: ${theme.colors.instat};
`;
const RedText = styled.span`
  color: ${theme.colors.red1};
`;
const YellowText = styled.span`
  color: ${theme.colors.calendula};
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const PromoInfoBlock = styled.div`
  padding-top: 49px;
  font-size: 14px;
  font-weight: 600;
  font-family: "SF Pro Display", sans-serif; ;
`;

// const mock: PromoMatchInfo[] = [
//   {
//     CardType: "1",
//     MatchId: "cb00c283-6df4-438a-9e1d-78a3f9eb18fe",
//     Status: PromoMatchStatus.Publish,
//     StartPublish: "2023-10-24T12:47:53.102Z",
//     EndPublish: "2023-11-03T13:47:59.102Z",
//   },
//   {
//     CardType: "2",
//     MatchId: "00585785-6ec0-4b31-8048-3678d41370dc",
//     Status: PromoMatchStatus.Scheduled,
//   },
//   {
//     CardType: "3",
//     MatchId: "",
//     Status: PromoMatchStatus.None,
//   },
//   {
//     CardType: "4",
//     MatchId: "",
//     Status: PromoMatchStatus.Scheduled,
//   },
//   {
//     CardType: "5",
//     MatchId: "",
//     Status: PromoMatchStatus.None,
//   },
// ];
