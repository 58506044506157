import { EyeInvisibleOutlined, EyeOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Modal, Tooltip } from "antd";
import { FormInstance } from "antd/es/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { SelectValue } from "antd/lib/select";
import { theme } from "assets/theme/theme";
import { formsConstantsValidation } from "common/constants/formsConstantsValidation";
import i18n from "common/i18n";
import {
  PromoMatchForForm,
  PromoMatchInfo,
  PromoMatchStatus,
  SortingPromoMatchInfo,
} from "common/interfaces/matchPromo";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { ownTeamsOptionsSelector } from "store/dictionary/dictionarySelectors";
import { getEventsOfPromoMatch } from "store/promoMatch/promoMatchAsync";
import { promoMatchesListSelector } from "store/promoMatch/promoMatchSelector";
import styled from "styled-components";
import { SelectField } from "ui/SelectField";

interface IMatchPromoCardProps {
  form: FormInstance;
  setCardEvents: React.Dispatch<React.SetStateAction<PromoMatchInfo["Event"][]>>;
  matchPromo?: PromoMatchForForm;
  cardType: keyof SortingPromoMatchInfo;
  reset: (val: keyof SortingPromoMatchInfo) => void;
  default: (val: keyof SortingPromoMatchInfo) => void;
  onHidden: (val: keyof SortingPromoMatchInfo, isShow: boolean) => void;
}

export const MatchPromoCard = (props: IMatchPromoCardProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { RangePicker } = DatePicker;
  const { promoMatchesList } = useSelector(promoMatchesListSelector);
  const [eventsOptions, setEventsOptions] = useState<SelectValue>();
  const teamOptions = useSelector(ownTeamsOptionsSelector);
  const [status, setStatus] = useState(props.matchPromo?.Status || "Hide");
  const [teamId, setTeamId] = useState<string>();
  const lang = i18n.language === "ru" ? "Ru" : "En";

  const renderDot = useMemo(() => {
    switch (status) {
      case PromoMatchStatus.None:
        return <Dot color="red1" />;
      case PromoMatchStatus.Published:
        return <Dot color="instat" />;
      case PromoMatchStatus.Scheduled:
        return <Dot color="calendula" />;
      default:
        return <Dot color="red1" />;
    }
  }, [status]);

  useEffect(() => {
    dispatch(getEventsOfPromoMatch(teamId))
      .unwrap()
      .then((res: any) => {
        props.setCardEvents(res);
        setEventsOptions(
          res.map((event: any) => {
            return {
              value: event.Id,
              label: `${event.Name[lang]} - ${moment(event.Date).format("DD/MM/YYYY")}`,
            };
          })
        );
      });
  }, [teamId]);

  useEffect(() => {
    if (props?.matchPromo?.TeamId) {
      setTeamId(props.matchPromo?.TeamId);
    }
  }, [promoMatchesList]);

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>
          {t("matchPromo.card.title") + " " + t(`matchPromo.number.${props.cardType}`)}{" "}
          <div>
            {status !== "Hide" ? (
              <EyeOutlined
                onClick={() => {
                  props.onHidden(props.cardType, false);
                  setStatus("Hide");
                }}
              />
            ) : (
              <Tooltip placement="topLeft" color="#8C8C8C" title="Не отображается на главном экране">
                <EyeInvisibleOutlined
                  onClick={() => {
                    props.onHidden(props.cardType, true);
                    setStatus(props.matchPromo?.Status || "None");
                  }}
                />
              </Tooltip>
            )}
          </div>
        </HeaderText>

        <StateDiv>
          {renderDot}
          {t("matchPromo.status." + status)}
        </StateDiv>
      </HeaderContainer>

      <FormItem name={[props.cardType, "TeamId"]} label={t("matchPromo.card.team")}>
        <SelectField
          options={teamOptions || []}
          defaultValue={teamOptions.find((option) => option.Id === teamId)}
          onChange={(id) => {
            setTeamId(id);
            props.form.setFieldsValue({ [props.cardType]: { MatchId: undefined } });
          }}
        />
      </FormItem>
      <FormItem name={[props.cardType, "MatchId"]} label={t("matchPromo.card.matches")}>
        <SelectField
          //@ts-ignore
          options={eventsOptions || []}
        />
      </FormItem>
      <FormItem name={[props.cardType, "DateTime"]} label={t("matchPromo.card.publishPeriod")}>
        <RangePicker
          showTime
          style={{ width: "100%" }}
          disabledDate={(date) => moment(date) < moment().set({ seconds: -1 })}
          format={formsConstantsValidation.dateFormat}
        />
      </FormItem>

      <FormItem name={[props.cardType, "Status"]} style={{ visibility: "hidden", margin: 0, height: 0 }}>
        <Input />
      </FormItem>

      <Footer>
        <Button
          style={{ marginRight: 8 }}
          onClick={() => {
            Modal.confirm({
              title: <HeaderText>Вы действительно хотите отменить?</HeaderText>,
              icon: <QuestionCircleOutlined style={{ color: theme.colors.red1 }} />,
              content: "Все изменения будут отменены.",
              okText: t("allPages.buttonsText.confirm"),
              cancelText: t("allPages.buttonsText.cancel"),
              onOk: () => {
                props.reset(props.cardType);
                setStatus(props.matchPromo?.Status || "Hide");
              },
            });
          }}
        >
          {t("allPages.buttonsText.cancel")}
        </Button>

        <Button
          style={{ marginRight: 8 }}
          onClick={() => {
            Modal.confirm({
              title: <HeaderText>Вы действительно хотите сбросить по умолчанию?</HeaderText>,
              icon: <QuestionCircleOutlined style={{ color: theme.colors.red1 }} />,
              content: "После подтверждения, настройки для данной карточки станут по умолчанию.",
              okText: t("allPages.buttonsText.confirm"),
              cancelText: t("allPages.buttonsText.cancel"),
              onOk: () => {
                props.default(props.cardType);
                setStatus("None");
              },
            });
          }}
          danger
        >
          {t("allPages.buttonsText.default")}
        </Button>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  border: 1px dashed ${theme.colors.black};
  height: fit-content;
  padding: 16px 20px 26px 23px;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
`;

const HeaderText = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;

  svg {
    margin-left: 16px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 26px;
`;
const StateDiv = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
`;

const Dot = styled.div<{ color: keyof typeof theme.colors }>`
  height: 6px;
  width: 6px;
  background-color: ${({ color }) => theme.colors[`${color}`]};
  border-radius: 50%;
  display: inline-block;
`;
