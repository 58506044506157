import { createSlice } from "@reduxjs/toolkit";
// import { AnswerEnum, AppearanceEnum, IQuiz } from "common/interfaces/IQuiz";
import { IConcert } from "pages/concert/interfaces/IConcert";
import { getConcertByFilter, getConcertById } from "./concertActionAsync";
import { IConcertRequest } from "pages/concert/interfaces/IConcertRequest";

export type ConcertState = {
  isLoading: boolean;
  count: number;
  concert?: IConcertRequest;
  concertList?: IConcert[];
};

export const concertInitialState: ConcertState = {
  isLoading: false,
  count: 0,
};

export const { actions: concertActions, reducer: concertReducer } = createSlice({
  name: "quiz",
  initialState: concertInitialState,
  reducers: {
    resetConcert(state) {
      state.concert = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConcertByFilter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConcertByFilter.fulfilled, (state, { payload }) => {
        state.count = payload["@odata.count"];
        state.concertList = payload.value;
        state.isLoading = false;
      })
      .addCase(getConcertByFilter.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getConcertById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConcertById.fulfilled, (state, { payload }) => {
        state.concert = {
          Id: payload.Id,
          ImageUrl: payload.Image,
          Description: payload.Description,
          EventId: payload.Event?.Id,
          FullName: payload.FullName,
          IsOver: payload.IsOver,
          StadiumId: payload.Stadium?.Id,
          StartDateTime: payload.StartDateTime,
          VisitorsNumber: payload.VisitorsNumber,
          BuyType: payload.BuyType,
          ExternalBuyInfo: payload.ExternalBuyInfo,
        };
        state.isLoading = false;
      })
      .addCase(getConcertById.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
