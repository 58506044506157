import { IStoryFilter } from "common/interfaces/IStory";
import { BaseApiService } from "./BaseApiService";

class StoryRepository extends BaseApiService {
  constructor() {
    super("media");
  }

  fetchByFilter = async (filter: IStoryFilter) => await this.get(`odata/Story?${this.getODataQuery(filter)}`);

  updateEndPublicationDate = async (id: string, publishEndDate: string) =>
    this.put<string>(`Story/UpdateEndPublicationDate/${id}?publishEndDate=${publishEndDate}&api-version=1.0`, "");

  updateBeginningPublicationDate = async (id: string, beginningPublishDate: string) =>
    this.put<string>(
      `Story/UpdateBeginningPublicationDate/${id}?beginningPublishDate=${beginningPublishDate}&api-version=1.0`,
      ""
    );

  updateSortOrder = async (body: string) => this.put<string>(`Story/UpdateSortOrder`, body);

  updateContent = async (id: string, body: string) => this.put<string>(`Story/UpdateStory/${id}`, body);

  addContent = async (body: string) => this.post<string>("Story/AddStory", body);

  deleteContent = async (deleteId: string) => this.delete<string>(`Story/DeleteStory/${deleteId}?api-version=1.0`);

  updateStatusContent = async (Id: string, storyStatus: string) =>
    this.put<string>(`Story/UpdateStatus/${Id}?storyStatus=${storyStatus}&api-version=1.0`, "");
}

export const storyRepository = new StoryRepository();
