import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addStoryThunk, getAllStoryThunk, removeStoryThunk, updateStoryThunk } from "./storyActionAsync";
import { IStory } from "common/interfaces/IStory";

export const storyAdapter = createEntityAdapter<IStory>({
  selectId: (story) => story.Id,
});

export const { actions: storyActions, reducer: storyReducer } = createSlice({
  name: "story",
  initialState: storyAdapter.getInitialState<{ isLoading: boolean; disabledResetFilters: boolean; count: number }>({
    isLoading: false,
    disabledResetFilters: false,
    count: 0,
  }),
  reducers: {},
  extraReducers: {
    [getAllStoryThunk.pending.type]: (state) => {
      state.isLoading = true;
      state.disabledResetFilters = true;
    },
    [getAllStoryThunk.fulfilled.type]: (state, { payload }: PayloadAction<{ allStories: IStory[]; count: number }>) => {
      state.count = payload.count;
      storyAdapter.setAll(state, payload.allStories);
      state.isLoading = false;
      state.disabledResetFilters = false;
    },
    [getAllStoryThunk.rejected.type]: (state) => {
      state.isLoading = false;
      state.disabledResetFilters = false;
    },
    [addStoryThunk.pending.type]: (state) => {
      state.isLoading = true;
      state.disabledResetFilters = true;
    },
    [addStoryThunk.fulfilled.type]: (state, { payload }: PayloadAction<IStory>) => {
      state.isLoading = false;
      state.count += 1;
      state.ids.unshift(payload.Id);
      state.entities[payload.Id] = payload;
    },
    [addStoryThunk.rejected.type]: (state) => {
      state.isLoading = false;
      state.disabledResetFilters = false;
    },
    [updateStoryThunk.pending.type]: (state) => {
      state.isLoading = true;
      state.disabledResetFilters = true;
    },
    [updateStoryThunk.fulfilled.type]: (state, { payload }: PayloadAction<IStory>) => {
      storyAdapter.updateOne(state, { id: payload.Id, changes: payload });
      state.isLoading = false;
    },
    [updateStoryThunk.rejected.type]: (state) => {
      state.isLoading = false;
      state.disabledResetFilters = false;
    },
    [removeStoryThunk.pending.type]: (state) => {
      state.isLoading = true;
      state.disabledResetFilters = true;
    },
    [removeStoryThunk.fulfilled.type]: (state, { payload }: PayloadAction<number>) => {
      storyAdapter.removeOne(state, payload);
      state.isLoading = false;
      state.disabledResetFilters = false;
      state.count -= 1;
    },
    [removeStoryThunk.rejected.type]: (state) => {
      state.isLoading = false;
      state.disabledResetFilters = false;
    },
  },
});
