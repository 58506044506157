import { LocaleType } from "./common";

export const enum PromoMatchStatus {
  Hide = "Hide",
  None = "None",
  Published = "Published",
  Scheduled = "Scheduled",
}

export type PromoMatchForForm = {
  DateTime?: any[];
  Status?: PromoMatchStatus;
  MatchId?: string;
  CardType?: keyof SortingPromoMatchInfo;
  TeamId?: string;
};

export type SortingPromoMatchInfo = {
  FirstCard?: PromoMatchForForm;
  SecondCard?: PromoMatchForForm;
  ThirdCard?: PromoMatchForForm;
  FourthCard?: PromoMatchForForm;
  FifthCard?: PromoMatchForForm;
};

export type EventOfPromoMatch = {
  Id: string;
  Name: LocaleType;
  Date: string;
  CardViewType: string;
  TeamId: string;
};

export type PromoMatchInfo = {
  StartPublish?: string;
  EndPublish?: string;
  Event?: {
    Id: string;
    Name: LocaleType;
    Date: string;
    CardViewType: string;
    TeamId: string;
  };
  CardType: keyof SortingPromoMatchInfo;
  Status: PromoMatchStatus;
};

export type PromoMatchRequest = {
  PromoCards: PromoMatchInfo[];
};
