import { createAsyncThunk } from "@reduxjs/toolkit";
import { storyRepository } from "api/storyRepository";
import { IStory, IStoryFilter, IStoryResponse, IStorySortUpdate } from "common/interfaces/IStory";
import i18next from "i18next";
import { validationPublishDate } from "pages/story/helpers/validationStory";
import { store } from "store";
import { noticeActions } from "store/notice/notice";

export const getAllStoryThunk = createAsyncThunk("story/getAllStory", async (filters: IStoryFilter) => {
  const response = await storyRepository.fetchByFilter(filters);

  const allStories = response.value.map((story: IStoryResponse) => {
    return story;
  });

  return { allStories, count: response["@odata.count"] };
});

export const updateEndPublicationDateThunk = createAsyncThunk(
  "story/updateEndPublicationDate",
  async (storyData: IStory, { dispatch }) => {
    validationPublishDate(storyData.BeginningPublication, storyData.EndPublication, "endPublish");
    if (storyData.EndPublication) {
      await storyRepository.updateEndPublicationDate(storyData.Id, storyData.EndPublication);
      store.dispatch(noticeActions.add({ type: "success", message: i18next.t("success.update.story.publishDate") }));
      // message.success(i18next.t("success.update.story.publishDate"));
    }
  }
);

export const updateBeginningPublicationDateThunk = createAsyncThunk(
  "story/updateBeginningPublicationDate",
  async (storyData: IStory, { dispatch }) => {
    validationPublishDate(storyData.BeginningPublication, storyData.EndPublication, "startPublish");
    if (storyData.BeginningPublication) {
      await storyRepository.updateBeginningPublicationDate(storyData.Id, storyData.BeginningPublication);
      store.dispatch(noticeActions.add({ type: "success", message: i18next.t("success.update.story.publishDate") }));
      // message.success(i18next.t("success.update.story.publishDate"));
    }
  }
);

export const updateStatusStoryThunk = createAsyncThunk<void, { entity: IStory; status: string }>(
  "story/updateStatusStory",
  async ({ entity, status }) => {
    const updatedStory = await storyRepository.updateStatusContent(entity.Id, status);
    store.dispatch(noticeActions.add({ type: "success", message: i18next.t(`success.update.story.status${status}`) }));
    // message.success(i18next.t(`success.update.story.status${status}`));

    return updatedStory;
  }
);

export const updateSortOrderThunk = createAsyncThunk("story/updateSortOrder", async (orderData: IStorySortUpdate) => {
  await storyRepository.updateSortOrder(JSON.stringify(orderData));
  store.dispatch(noticeActions.add({ type: "success", message: i18next.t("success.update.story.sortOrder") }));
  // message.success(i18next.t("success.update.story.sortOrder"));
});

export const updateStoryThunk = createAsyncThunk("story/updateStory", async (storyUpdate: IStory) => {
  const body = JSON.stringify(storyUpdate);
  const res = await storyRepository.updateContent(storyUpdate.Id, body);
  store.dispatch(noticeActions.add({ type: "success", message: i18next.t("success.update.story.content") }));
  // message.success(i18next.t("success.update.story.content"));
  // const updatedStory = { res};

  return res;
});

export const addStoryThunk = createAsyncThunk("story/addStory", async (dataStory: IStory) => {
  const newStory = await storyRepository.addContent(JSON.stringify(dataStory));
  store.dispatch(noticeActions.add({ type: "success", message: i18next.t("success.create.story") }));
  // message.success(i18next.t("success.create.story"));

  return newStory;
});

export const removeStoryThunk = createAsyncThunk("story/removeStory", async (entity: IStory) => {
  await storyRepository.deleteContent(entity.Id);
  store.dispatch(noticeActions.add({ type: "success", message: i18next.t("success.delete.story.content") }));
  // message.success(i18next.t("success.delete.story.content"));

  return;
});
