import { createSlice } from "@reduxjs/toolkit";
import { AlertProps } from "antd";

export type Notice = AlertProps & {
  timeout?: number;
};

type InitialStateType = Notice & { key: number };

const initialState: InitialStateType[] = [];

export const { actions: noticeActions, reducer: noticeReducer } = createSlice({
  name: "notice",
  initialState: initialState,
  reducers: {
    add(state, { payload }: { payload: Notice }) {
      if (state.length > 12) {
        state.shift();
      }
      const key = Date.now();
      state.push({ timeout: 5000, closable: true, ...payload, key });
    },
    remove(state, { payload }) {
      return state.filter(({ key }) => key !== payload);
    },
  },
});
