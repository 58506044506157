export const routePaths = {
  base: "/",
  media: "media",
  story: "story",
  concert: "concert",
  mediaCategories: "media/categories",
  pagesSections: "pages/sections",
  pagesSubscriptions: "pages/subscriptions",
  partners: "partners",
  calendar: "calendar",
  tournaments: (id = "", form?: string) => `tournaments${id ? `/${id}` : ""}${form ? `/${form}` : ""}`,
  matches: (id = "", form?: string) => `matches${id ? `/${id}` : ""}${form ? `/${form}` : ""}`,
  matching: "matching",
  matchesPromo: "matchesPromo",
  clubsTeams: "clubs/teams",
  clubsPlayers: "clubs/players",
  clubsTrainers: "clubs/trainers",
  clubsStaff: "clubs/staff",
  clubsMedical: "clubs/medical",
  clubsLeadership: "clubs/leadership",
  dirStadiums: "directories/stadiums",
  dirTeams: "directories/teams",
  advertising: "advertising",
  employee: "employee",
  employeeRole: "employee/role",
  usersView: "users/view",
  systemLog: "systemLog",
  notifications: (tab = ":tab") => `notifications/${tab}`,
  predictor: "predictor",
  information: "information",
  backup: "backup",
  settings: "settings",
  bestPlayer: "promo/best-player",
  graduates: "graduates",
  graduateSections: "graduate-sections",
  kidsJournals: "kids-journals",
  kidsSeasons: "kids-seasons",
  kidsBidToExit: "bids-to-exit",
  loyalty: "loyalty",
  specialOffer: "specialOffer",
  quiz: "quiz",
  polls: "polls",
  sign: {
    auth: "auth",
    forgotPassword: "forgotPassword",
    recoveryPassword: "recoveryPassword",
    invitation: "invitation",
    recoveryPasswordSuccess: "recoveryPasswordSuccess/:type",
    invitationSuccess: "invitationSuccess/:type",
    recoveryPasswordError: "recoveryPasswordError/:type",
    invitationError: "invitationError/:type",
  },
  form: {
    create: "create",
    edit: (id = ":id") => id,
  },
};
