export const theme = {
  colors: {
    white: "#ffffff",
    blue: "#1890FF",
    gray: "#595959",
    middleGray: "#8C8C8C",
    neroGray: "#262626",
    lightGray: "#BFBFBF",
    pureGray: "#FAFAFA",
    lightRed: "#FFF2F0",
    lightRed1: "#FFCCC7",
    red: "#F5222D",
    red1: "#DC001F",
    dustRed1: "#FFF1F0",
    dustRed5: "#FF4D4F",
    orange: "#FA541C",
    grayLightWhite: "#D9D9D9",
    lightGrayAnt: "#f0f0f0",
    lightGrayWhite: "#F5F5F5",
    tabulateItemText: "#EAEAEA",
    lightGreen: "#F6FFED",
    lightGreen1: "#C1EE9F",
    green: "#52C41A",
    black: "#000000",
    default: "#F5222D",
    lightOrange: "#FFBB96",
    volcano1: "#FFF2E8",
    instat: "#0F7335",
    calendula: "#FFC53D",
  },
};
