import { createSlice } from "@reduxjs/toolkit";
import { PromoMatchInfo, PromoMatchStatus, SortingPromoMatchInfo } from "common/interfaces/matchPromo";
import { SelectFieldOption } from "ui/SelectField";
import { getEventsOfPromoMatch, getPromoMatches } from "./promoMatchAsync";
import moment from "moment";
import { viewDate } from "common/helpers/viewDate";

export type MatchPromoState = {
  isLoading: boolean;
  promoMatchesList: SortingPromoMatchInfo;
  eventsList?: PromoMatchInfo["Event"][];
  eventsOptions?: SelectFieldOption[];
};

export const matchPromoInitialState: MatchPromoState = {
  promoMatchesList: {},
  eventsList: [],
  isLoading: false,
};

export const { actions: promoMatchActions, reducer: promoMatchReducer } = createSlice({
  name: "promoMatch",
  initialState: matchPromoInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEventsOfPromoMatch.fulfilled, (state, { payload }) => {
        state.eventsList = payload;
        state.eventsOptions = payload.map((match) => ({
          label: match.Name.Ru + " - " + moment(match.Date).format("YYYY/MM/DD"),
          value: match.Id,
        }));
      })

      .addCase(getPromoMatches.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPromoMatches.fulfilled, (state, { payload }) => {
        const FirstCard = payload.find((elem) => elem.CardType === "FirstCard");
        const SecondCard = payload.find((elem) => elem.CardType === "SecondCard");
        const ThirdCard = payload.find((elem) => elem.CardType === "ThirdCard");
        const FourthCard = payload.find((elem) => elem.CardType === "FourthCard");
        const FifthCard = payload.find((elem) => elem.CardType === "FifthCard");

        state.promoMatchesList = {
          FirstCard: {
            DateTime:
              FirstCard?.["StartPublish"] && FirstCard?.["EndPublish"]
                ? [moment(viewDate(FirstCard?.["StartPublish"])), moment(viewDate(FirstCard?.["EndPublish"]))]
                : undefined,
            Status: FirstCard?.Status,
            MatchId: FirstCard?.Event?.Id,
            CardType: FirstCard?.CardType,
            TeamId: FirstCard?.Event?.TeamId,
          },
          SecondCard: {
            DateTime:
              SecondCard?.["StartPublish"] && SecondCard?.["EndPublish"]
                ? [moment(viewDate(SecondCard?.["StartPublish"])), moment(viewDate(SecondCard?.["EndPublish"]))]
                : undefined,
            Status: SecondCard?.Status,
            MatchId: SecondCard?.Event?.Id,
            CardType: SecondCard?.CardType,
            TeamId: SecondCard?.Event?.TeamId,
          },
          ThirdCard: {
            DateTime:
              ThirdCard?.["StartPublish"] && ThirdCard?.["EndPublish"]
                ? [moment(viewDate(ThirdCard?.["StartPublish"])), moment(viewDate(ThirdCard?.["EndPublish"]))]
                : undefined,
            Status: ThirdCard?.Status,
            MatchId: ThirdCard?.Event?.Id,
            CardType: ThirdCard?.CardType,
            TeamId: ThirdCard?.Event?.TeamId,
          },
          FourthCard: {
            DateTime:
              FourthCard?.["StartPublish"] && FourthCard?.["EndPublish"]
                ? [moment(viewDate(FourthCard?.["StartPublish"])), moment(viewDate(FourthCard?.["EndPublish"]))]
                : undefined,
            Status: FourthCard?.Status,
            MatchId: FourthCard?.Event?.Id,
            CardType: FourthCard?.CardType,
            TeamId: FourthCard?.Event?.TeamId,
          },
          FifthCard: {
            DateTime:
              FifthCard?.["StartPublish"] && FifthCard?.["EndPublish"]
                ? [
                    moment(viewDate(FifthCard?.["StartPublish"] || "")),
                    moment(viewDate(FifthCard?.["EndPublish"] || "")),
                  ]
                : undefined,
            Status: FifthCard?.Status,
            MatchId: FifthCard?.Event?.Id,
            CardType: FifthCard?.CardType,
            TeamId: FifthCard?.Event?.TeamId,
          },
        };
        state.isLoading = false;
      })
      .addCase(getPromoMatches.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
