import { createAsyncThunk } from "@reduxjs/toolkit";
import { concertRepository } from "api/concertRepository";
import { BaseFilters, ThunkApiType } from "common/interfaces/common";
import { IConcert } from "pages/concert/interfaces/IConcert";
import { IConcertResponse } from "pages/concert/interfaces/IConcertResponse";

export const getConcertByFilter = createAsyncThunk<IConcertResponse, BaseFilters, ThunkApiType>(
  "concert/byFilter",
  async (filters, { rejectWithValue }) => {
    return await concertRepository.fetchByFilter(filters).catch((error) => rejectWithValue({ error }));
  }
);

export const getConcertById = createAsyncThunk<IConcert, IConcert["Id"], ThunkApiType>(
  "concert/byId",
  async (id, { rejectWithValue }) => {
    return await concertRepository.fetchById(id).catch((error) => rejectWithValue({ error }));
  }
);
