import { BaseFilters } from "common/interfaces/common";
import { IPoll } from "pages/polls/interfaces/IPoll";
import { BaseApiService } from "./BaseApiService";

class PollsRepository extends BaseApiService {
  constructor() {
    super("advertisement");
  }

  fetchByFilter = async (filter: BaseFilters) => {
    return await this.get(`odata/Poll?${this.getODataQuery(filter)}`);
  };

  fetchById = async (id: IPoll["Id"]) => {
    const { value } = await this.get(
      `odata/Poll?$expand=Questions($filter=DeletedUtc eq null;$expand=Answers($filter=DeletedUtc eq null))&$filter=Id eq ${id}`
    );

    return value[0];
  };

  publish = async (quiz: IPoll) => await this.post("Poll/Publish", JSON.stringify(quiz));

  draft = async (quiz: IPoll) => await this.post("Poll/Draft", JSON.stringify(quiz));

  remove = async (id: IPoll["Id"]) => await this.delete(`Poll/Delete?pollId=${id}`);

  uploadImage = async (type: string, file: File) => {
    const formData = new FormData();
    formData.append("formFile", file);

    return await this.post(`Poll/AddImage?type=${type}&api-version=1.0`, formData, {
      headers: {},
    }).catch(() => file.name);
  };

  fetchExportReport = (quizId: IPoll["Id"]) =>
    this.post(`Poll/ExportReport?pollId=${quizId}`, undefined, {
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
}

export const pollsRepository = new PollsRepository();
