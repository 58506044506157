export const accessNames = {
  fullAccess: "fullAccess",
  media: "media",
  mediaSite: "media.site",
  mediaAcademy: "media.academy",
  cms: "cms",
  partner: "partner",
  partnerSite: "partner.site",
  partnerAcademy: "partner.academy",
  calendar: "calendar",
  tournament: "tournament",
  club: "club",
  clubSite: "club.site",
  clubAcademy: "club.academy",
  graduate: "graduate",
  notification: "notification",
  predictor: "predict",
  stock: "stock",
  banner: "banner",
  audit: "audit",
  employee: "employee",
  role: "role",
  kids: "kids",
  loyalty: "loyalty",
  bindToExit: "bind-to-exit",
  specialOffer: "special-offer",
  quiz: "quiz",
  story: "story",
  concert: "concert",
  poll: "poll",
};
