import { BaseFilters } from "common/interfaces/common";
import { IConcert } from "pages/concert/interfaces/IConcert";
import { BaseApiService } from "./BaseApiService";
import { IConcertRequest } from "pages/concert/interfaces/IConcertRequest";

class ConcertRepository extends BaseApiService {
  constructor() {
    super("match");
  }

  fetchByFilter = async (filter: BaseFilters) => {
    console.log("filter", filter);

    return await this.get(`odata/Concert?$expand=Stadium,Event&${this.getODataQuery(filter)}`);
  };

  fetchById = async (id: IConcert["Id"]) => {
    const { value } = await this.get(`odata/Concert?$expand=Stadium,Event&$filter=Id eq ${id}`);

    return value[0];
  };

  publish = async (quiz: IConcertRequest) => await this.post("Concert/Publish", JSON.stringify(quiz));

  draft = async (quiz: IConcertRequest) => await this.post("Concert/Draft", JSON.stringify(quiz));

  remove = async (id: IConcert["Id"]) => await this.delete(`Concert/Delete?id=${id}`);
}

export const concertRepository = new ConcertRepository();
