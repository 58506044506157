export interface IAttributesImage {
  width: number;
  height: number;
  size: number;
}
export const formsConstantsValidation = {
  inputImage: {
    files: {} as any,
  },
  default: {
    min: 3,
    max: 254,
  },
  password: {
    min: 8,
    max: 20,
  },
  textarea: {
    max: 300,
  },
  link: {
    max: 2048,
  },
  entity: {
    default: {
      min: 3,
      wmax: 3,
      max: 50,
    },
    story: {
      image: {
        width: 1080,
        height: 1920,
        size: 15,
      },
      imageMin: {
        width: 400,
      },
      video: {
        width: 1080,
        height: 1920,
        size: 20,
      },
    },
    adv: {
      max: 25,
      image: {
        width: 51000,
        height: 5000,
        size: 1048576,
      },
    },
  },
  dateFormat: "DD-MM-YYYY HH:mm",
  dateTimeFormat: "DD/MM/YYYY HH:mm",
  timeFormat: "HH:mm",
};
