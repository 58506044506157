import { StateType } from "../index";
import { createSelector } from "@reduxjs/toolkit";

const promoMatchState = ({ promoMatch }: StateType) => promoMatch;

export const promoMatchesListSelector = createSelector(
  promoMatchState,
  ({ promoMatchesList, eventsList, isLoading, eventsOptions }) => ({
    promoMatchesList,
    eventsList,
    eventsOptions,
    isLoading,
  })
);
