import { EventOfPromoMatch, PromoMatchInfo, PromoMatchRequest } from "common/interfaces/matchPromo";
import { BaseApiService } from "./BaseApiService";

class PromoMatchRepository extends BaseApiService {
  constructor() {
    super("match");
  }

  fetchPromoCards = async (): Promise<PromoMatchInfo[]> => await this.get(`PromoCard/Get?api-version=1.0`);

  fetchEventsOfPromoMatch = async (teamId?: string): Promise<EventOfPromoMatch[]> => {
    return await this.get(`PromoCard/GetEvents?${teamId ? `teamId=${teamId}&` : ""}api-version=1.0`);
  };

  savePromoMatches = async (body: PromoMatchRequest) => {
    return this.post(`PromoCard/Save?api-version=1.0`, JSON.stringify(body));
  };
}

export const promoMatchRepository = new PromoMatchRepository();
