import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPoll } from "pages/polls/interfaces/IPoll";
import { getPollById, getPollsByFilter } from "./pollsActionAsync";
import { deepMerge } from "common/helpers/deepMerge";

export type PollState = {
  isLoading: boolean;
  count: number;
  poll?: IPoll;
  pollList?: IPoll[];
};

export const pollsInitialState: PollState = {
  isLoading: false,
  count: 0,
  poll: {} as IPoll,
};

export const { actions: pollsActions, reducer: pollsReducer } = createSlice({
  name: "polls",
  initialState: pollsInitialState,
  reducers: {
    resetPolls(state) {
      state.poll = {} as IPoll;
    },
    changePoll(state, action: PayloadAction<IPoll>) {
      state.poll = deepMerge(state.poll!, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPollsByFilter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPollsByFilter.fulfilled, (state, { payload }) => {
        state.count = payload["@odata.count"];
        state.pollList = payload.value;
        state.isLoading = false;
      })
      .addCase(getPollsByFilter.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPollById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPollById.fulfilled, (state, { payload }) => {
        state.poll = { ...payload, Questions: payload.Questions.sort((a, b) => a.Num - b.Num) };
        state.isLoading = false;
      })
      .addCase(getPollById.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
